






import Vue from "@/translate";
import ManagerLayout from "@/layouts/ManagerLayout.vue";

export default Vue.extend({
  name: "Manager",
  components: {
    ManagerLayout,
  },
});
