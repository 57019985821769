

























































































































import Vue from "@/translate";
import AppError from "@/components/common/AppError.vue";
import AppFooter from "@/components/common/AppFooter.vue";
import { ROOT_ACTIONS, SHOW_ERROR } from "@/store/actions";
import ManagerMixin from "@/mixins/manager";
import ColorsMixin from "@/mixins/color-scheme";
import AppDropzone from "@/components/common/AppDropzone.vue";
import ManagerClient from "@/client/manager";
import LanguageIcon from "@/components/LanguageIcon.vue";
import AppMain from "@/components/common/AppMain.vue";
import UserMenu from "@/layouts/UserMenu.vue";

export default Vue.extend(ManagerMixin)
  .extend(ColorsMixin)
  .extend({
    name: "ManagerLayout",
    components: {
      UserMenu,
      AppMain,
      AppDropzone,
      AppFooter,
      AppError,
      LanguageIcon,
    },
    data() {
      return {
        auto: false,
        mini: false,
      };
    },
    async created() {
      const actions = await ManagerClient.RootQuery();
      await this.$store.dispatch(ROOT_ACTIONS, actions);
    },
    methods: {
      async uploadPlunet(fileList: FileList): Promise<void> {
        try {
          const file = fileList.item(0);
          if (file) {
            const id = await ManagerClient.UploadPlunetCommand(file);
            await this.$router.push({
              name: "MProjectDetail",
              params: { projectId: id },
            });
          }
        } catch (e: any) {
          await this.$store.dispatch(SHOW_ERROR, e.message);
        }
      },
      async uploadEDI(fileList: FileList): Promise<void> {
        try {
          const file = fileList.item(0);
          if (file) {
            const id = await ManagerClient.UploadEDICommand(file);
            await this.$router.push({
              name: "MInvoiceDetail",
              params: { invoiceId: id },
            });
          }
        } catch (e: any) {
          await this.$store.dispatch(SHOW_ERROR, e.message);
        }
      },
    },
  });
